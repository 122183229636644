<template>
  <section class="missingPage">
    <div class="container">
      <div class="row">
        <div class="col-sm-10 offset-sm-1 col-12">
          <div class="missingPage__title">404</div>
          <div class="missingPage__subtitle">
            К сожалению по данной ссылке сохраненных объектов не найдено, возможно Вы попали сюда
            случайно?
          </div>
          <div class="missingPage__subtitle">Вы можете начать расчет заново.</div>
          <router-link to="/" class="missingPage__btn">Начать расчет</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {}
</script>

<style lang="sass" scoped>
.missingPage
  display: flex
  flex-direction: column
  &__title
    color: $red
    +media((font-size: (320: rem(80), 768: rem(150))))
    font-weight: 700
    text-shadow: rem(1) rem(1) 0 $black
  &__subtitle
    font-weight: 500
    color: $black
    padding-bottom: rem(20)
    +media((font-size: (320: rem(15), 768: rem(20))))
  &__btn
    background: $red
    color: $white
    font-style: normal
    font-weight: bold
    font-size: rem(18)
    line-height: rem(22)
    text-decoration: none
    transition: .5s
    width: rem(240)
    height: rem(68)
    border-radius: rem(4)
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    margin: auto auto rem(20)
    &:hover
      background: $red-hover
</style>
